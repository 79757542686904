<template>
  <div class="text-center">
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn x-small color="#d4b506" light v-bind="attrs" v-on="on">
          Withdraw
        </v-btn>
      </template>

      <v-card>
        <v-card-title class="text-h5 grey lighten-2">
          Withdraw
          <v-spacer />
          {{ currency }}
        </v-card-title>

        <v-card-text class="pa-5">
          <v-text-field
            v-model="walletId"
            label="Wallet ID"
            filled
            dense
          ></v-text-field>
          <v-text-field
            v-model="amount"
            label="$Amount"
            filled
            dense
          ></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#d4b506" light @click="Withdraw()"> Processed </v-btn>
          <v-btn color="primary" text @click="dialog = false"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
export default {
  props: ["currency"],
  data() {
    return {
      dialog: false,
      amount: "",
      walletId: "",
    };
  },
  computed: {
    getToken() {
      return this.$store.getters.getToken;
    },
  },
  methods: {
    Withdraw() {
      if (this.amount == "" || this.walletId == "") {
        this.$store.commit("setSnackbar", true);
        this.$store.commit("setServerMessage", "Fill All The Required Field");
        return;
      }
      let payload = {
        token: this.getToken,
        coin: this.currency,
        amount: this.amount,
        walletId: this.walletId,
      };
      this.$store.dispatch("Withdraw", payload);
      this.dialog = false;
    },
  },
};
</script>